"use client";
// Import Modules
import Image from "next/image";
import Carousel from "react-multi-carousel";

// Import Components
import Team from "@/components/Team";

// Import Styles
import "react-multi-carousel/lib/styles.css";
import "react-spring-bottom-sheet/dist/style.css";

// Import Assets
import Sec2 from "@/assets/images/quotes.svg";
import PPI from "@/assets/images/PPI.svg";
import npci from "@/assets/icons/npci.svg";
import ruPay from "@/assets/icons/ruPay.svg";
import visa from "@/assets/icons/visa.svg";
import rbi from "@/assets/icons/rbi.svg";
import upi from "@/assets/icons/upi.svg";
import FamX2 from "@/assets/images/FamXcard2.webp";
import Personalised from "@/assets/images/PersonalisedCard.webp";
import Payments from "@/assets/images/Payments.webp";
import Spending from "@/assets/images/Spending.webp";
import GetInTouch from "@/assets/images/GetInTouch.svg";
import PPIbG from "@/assets/images/PPIBG.svg";
import Header from "@/assets/images/Header.svg";

export default function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };

  const features = [
    {
      title: "Spending Account",
      subtitle:
        "Tri O Tech's PPI-UPI stack powers the payments of 1 million users of FamX",
      img: Spending,
    },
    {
      title: "Accelarating Payments",
      subtitle:
        "We've helped evolve their UPI and card payments to become some of the fastest in the country.",
      img: Payments,
    },
    {
      title: "Personalised cards",
      subtitle:
        "Card payments made fun for the users, while also safe and secure with Tri O Tech",
      img: Personalised,
    },
  ];

  return (
    <>
      <main className="bg-white">
        <Image
          draggable={false}
          className="w-full object-cover h-full relative bottom-4"
          src={Header}
          alt="Header"
        />
        <div className="p-16 md:p-28 relative bottom-10">
          <h2 className="text-[36px] md:text-giant font-extrabold my-4 text-trio-black">
            Our Vision
          </h2>
          <p className="w-full text-[16px] md:text-lg md:w-[60%] text-trio-black font-medium">
            At Tri O Tech, we strive to enable and build digital payments for
            everyone in India. We envision a cashless India, where every citizen
            is financially aware and independent. We aim to utilise our PPI to
            issue safer, faster, unified wallets and payment solutions.
          </p>
        </div>
        <div className="flex flex-col relative pt-trio mb-20 md:mb-0">
          <Image className="object-cover w-full h-fit" src={Sec2} alt="sec-2" />
          <div className="absolute inset-0 md:top-[35%] top-[15%] text-center p-trio">
            <h2 className="text-[36px] md:text-[72px] font-extrabold text-black">
              For Bharat by Bharat
            </h2>
            <br />
            <p className="text-black text-[16px]">
              Financial empowerment of people is our ultimate goal.
            </p>
            <small className="block ml-[20vw] text-trio-black text-md md:text-xl ">
              - PM Narendra Modi
            </small>
          </div>
        </div>
        <div className="flex justify-center my-6 relative">
          <Image className="w-[80vw] z-10 mx-auto" src={PPI} alt="PPI Stack" />
          <Image
            className="w-[100%] absolute h-[90%] blur object-cover top-[5%]"
            src={PPIbG}
            alt="bg-gradient"
          />
        </div>
        <hr />
        <div className="bg-trio-orange px-trio py-20 text-left">
          <h2 className="text-[36px] md:text-giant pb-4 font-extrabold ">
            Easy and fast <br /> integration
          </h2>
          <hr />
          <p className="pt-6 text-[16px] md:text-lg w-[80%]">
            Our payments solutions are end-to-end, which means we can help you
            launch your fintech product as fast as possible. From building the
            tech stack, to issuing accounts & cards, we&apos;ve got all bases
            covered for you.
          </p>
        </div>
        <div className="bg-trio-green px-trio py-16 my-1 text-left">
          <h2 className="text-[36px] md:text-giant   pb-4 font-extrabold ">
            Built for safety <br /> and trust
          </h2>
          <hr />
          <p className="w-[80%] text-[16px] md:text-lg pt-6">
            Our risk and fraud engines are iron clad. Built meticulously to
            eradicate any suspicious activity at its root, our PPI stack comes
            with the highest safety standards and checks in place.
          </p>
          <div className="flex mt-20 flex-wrap justify-between">
            <Image className="w-[12%] mr-2" src={visa} alt={""} />
            <Image className="w-[12%] mr-2" src={ruPay} alt={""} />
            <Image className="w-[12%] mr-2" src={npci} alt={""} />
            <Image className="w-[12%] mr-2" src={upi} alt={""} />
            <Image className="w-[30%] mr-2" src={rbi} alt={""} />
          </div>
        </div>
        <div className="bg-trio-black py-20 px-trio text-left">
          <div className="text-left">
            <h2 className="text-[36px] md:text-giant  font-extrabold">
              Technology <br /> built for scale
            </h2>
            <br />
            <div className="min-h-[1px]  min-w-[100%] border-b opacity-30 my-6" />
            <p className="mt-4 w-[80%] text-[16px] md:text-lg">
              At Tri O Tech, our stack is designed to handle large transaction
              volumes and scale payments rapidly. We always think of users
              first, ensure high payment success rates, and provide smooth
              resolution.
            </p>
          </div>
        </div>

        <div className="p-trio pb-0 my-auto flex-col md:flex-row text-center md:text-left justify-between items-center">
          <div className="flex flex-col md:flex-row items-center justify-center relative">
            <h2 className="w-[50vw] mb-10 md:mb-0 text-[36px] md:text-[50px] text-trio-black font-extrabold">
              Our Products
              <button
                className="hidden md:block rounded-3xl px-6 py-2 mt-12 md:px-8 md:py-4 text-sm relative bg-trio-orange"
                onClick={() => {
                  window.open("https://famapp.in/");
                }}
              >
                Know more
              </button>
            </h2>
            <Image
              className="w-[50vw] md:w-[32vw] lg:w-[24vw]"
              src={FamX2}
              alt="card"
            />
          </div>
        </div>
        <div className="bg-white py-6 flex flex-col">
          {features.map((item, idx) => {
            const isEven = idx % 2 === 0;
            return (
              <div
                key={item.title}
                className={`my-5 w-full flex flex-col-reverse ${
                  isEven ? "md:flex-row" : "md:flex-row-reverse"
                } justify-between items-center`}
              >
                <Image
                  className={`h-[100%] md:w-[50vw] w-[81vw] flex ${
                    isEven ? "mr-auto" : "ml-auto"
                  }`}
                  alt={item.title}
                  src={item.img}
                />
                <div className="md:text-left text-center w-[80%] md:w-[40%] p-10">
                  <h2 className="text-[36px] md:text-giant font-extrabold  text-trio-black">
                    {item.title}
                  </h2>
                  <hr className="my-2" />
                  <p className="text-[16px] md:text-lg text-trio-black">
                    {item.subtitle}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <button
          className="md:hidden rounded-3xl px-6 py-2 md:px-8 md:py-4 text-sm relative top-4 md:-top-12 lg:-top-36 bg-trio-orange"
          onClick={() => {
            window.open("https://famapp.in/");
          }}
        >
          Know more
        </button>

        <div className="p-trio text-center">
          <h2 className="text-[36px] md:text-giant font-extrabold text-trio-black">
            People that make Tri O Tech possible
          </h2>
          <p className="text-[16px] md:text-lg mt-4 text-trio-black">
            Meet the faces who believe in the power of financial inclusion.
          </p>
        </div>
        <Team />

        <div>
          <Image
            className="w-full h-full object-cover"
            src={GetInTouch}
            alt="section"
          />
        </div>
      </main>
    </>
  );
}
