"use client";
// Import Modules
import Image from "next/image";

// People Assets
import KT from "@/assets/images/Employees/KT.png";
import JS from "@/assets/images/Employees/JS.jpg";
import Paaji from "@/assets/images/Employees/Paaji.jpg";
import Chirag from "@/assets/images/Employees/Chirag.png";
import Momo from "@/assets/images/Employees/Momo.jpg";
import Saransh from "@/assets/images/Employees/Saransh.jpg";
import AbhishekDas from "@/assets/images/Employees/Abhishek.jpg";
import Amarnath from "@/assets/images/Employees/Amarnath.jpeg";
import Manan from "@/assets/images/Employees/Manan.jpg";
// import Monica from "@/assets/images/Employees/Monica.jpeg";
import PSJ from "@/assets/images/Employees/PSJ.jpeg";
import Richa from "@/assets/images/Employees/Richa.jpg";
import Ritwick from "@/assets/images/Employees/Ritwick.png";
import Sandeep from "@/assets/images/Employees/Sandeep.jpg";
import Sangam from "@/assets/images/Employees/Sangam.jpeg";
import SrinidhiDG from "@/assets/images/Employees/Srinidhi.jpg";
import Sunil from "@/assets/images/Employees/Sunil.jpg";
import VishalJain from "@/assets/images/Employees/VishalJain.png";
import MuazzZuberi from "@/assets/images/Employees/MuazzZuberi.webp";
import PratikGajjar from "@/assets/images/Employees/PratikGajjar.png";
import SaurabhGothi from "@/assets/images/Employees/SaurabhGothi.webp";
import ShikharTyagi from "@/assets/images/Employees/ShikharTyagi.webp";
import SandeepSingh from "@/assets/images/Employees/SandeepSingh.webp";
import RaghavSharma from "@/assets/images/Employees/RaghavSharma.webp";
import PranavJain from "@/assets/images/Employees/PranavJain.webp";
import BanutejaB from "@/assets/images/Employees/BanutejaB.webp";
import ShouryaDe from "@/assets/images/Employees/ShouryaDe.webp";
import NamanNarula from "@/assets/images/Employees/NamanNarula.webp";
import ArpitaSingh from "@/assets/images/Employees/ArpitaSingh.webp";
import AneeshPanda from "@/assets/images/Employees/AneeshPanda.webp";
import PiyushDagdiya from "@/assets/images/Employees/PiyushDagdiya.webp";
import RajPandey from "@/assets/images/Employees/RajPandey.webp";
import AkschayaBalaji from "@/assets/images/Employees/AkschayaBalaji.webp";
import LishaKothari from "@/assets/images/Employees/LishaKothari.webp";

// Import Customer Support
import YarabM from "@/assets/images/Employees/CustomerSupport/YarabM.webp";
import ShaikTasneem from "@/assets/images/Employees/CustomerSupport/ShaikTasneem.webp";
import DSaiKrishna from "@/assets/images/Employees/CustomerSupport/DSaiKrishna.webp";
import SureshChandraRana from "@/assets/images/Employees/CustomerSupport/SureshChandraRana.webp";
import ShaikSaleem from "@/assets/images/Employees/CustomerSupport/SaleemShaik.webp";
import SohanBandekar from "@/assets/images/Employees/CustomerSupport/SohanBandekar.webp";
import MohammedAbbas from "@/assets/images/Employees/CustomerSupport/MohammedAbbas.webp";
import VishalaNidoni from "@/assets/images/Employees/CustomerSupport/VishalaNidoni.webp";
import PoojaPPurohit from "@/assets/images/Employees/CustomerSupport/PoojaPPurohit.webp";
import AsfiyaKhanum from "@/assets/images/Employees/CustomerSupport/AsfiyaKhanum.webp";
import SyedSaif from "@/assets/images/Employees/CustomerSupport/SyedSaif.webp";
import SheikhYousuf from "@/assets/images/Employees/CustomerSupport/SheikhYousuf.webp";
import SweetyChetry from "@/assets/images/Employees/CustomerSupport/SweetyChetry.webp";
import ShaikMalikBasha from "@/assets/images/Employees/CustomerSupport/ShaikMalikBasha.webp";
import SyedNadeem from "@/assets/images/Employees/CustomerSupport/SyedNadeem.webp";
import PreetiRK from "@/assets/images/Employees/CustomerSupport/PreetiRK.webp";
import ArabindaPator from "@/assets/images/Employees/CustomerSupport/ArabindaPator.webp";
import WinstonRichard from "@/assets/images/Employees/CustomerSupport/WinstonRichard.webp";
import RakeshSamal from "@/assets/images/Employees/CustomerSupport/RakeshSamal.webp";
import ThejasHS from "@/assets/images/Employees/CustomerSupport/ThejasHS.webp";
import KajalMurmu from "@/assets/images/Employees/CustomerSupport/KajalMurmu.webp";
import NitalPurohit from "@/assets/images/Employees/CustomerSupport/NitalPurohit.webp";
import RattanGowda from "@/assets/images/Employees/CustomerSupport/RattanGowda.webp";
import SandeshUBilgundi from "@/assets/images/Employees/CustomerSupport/SandeshUBilgundi.webp";
import FathimathulThehliyaK from "@/assets/images/Employees/CustomerSupport/FathimathulThehliyaK.webp";
import MadanKumarS from "@/assets/images/Employees/CustomerSupport/MadanKumarS.webp";
import InamAliKhan from "@/assets/images/Employees/CustomerSupport/InamAliKhan.webp";
import SyedGhouseBasha from "@/assets/images/Employees/CustomerSupport/SyedGhouseBasha.webp";
import DaneshwariBandi from "@/assets/images/Employees/CustomerSupport/DaneshwariBandi.webp";
import SandarshBhaskar from "@/assets/images/Employees/CustomerSupport/SandarshBhaskar.webp";
import MizbaBanu from "@/assets/images/Employees/CustomerSupport/MizbaBanu.webp";
import BaisnabiSahoo from "@/assets/images/Employees/CustomerSupport/BaisnabiSahoo.webp";
import RaniSahu from "@/assets/images/Employees/CustomerSupport/RaniSahu.webp";
import SyedMahamooda from "@/assets/images/Employees/CustomerSupport/SyedMahamooda.webp";
import SangitaMandal from "@/assets/images/Employees/CustomerSupport/SangitaMandal.webp";
import BhagyshreeHebbali from "@/assets/images/Employees/CustomerSupport/BhagyshreeHebbali.webp";
import SonaliRamesh from "@/assets/images/Employees/CustomerSupport/SonaliRamesh.webp";
import ShwethaSankapale from "@/assets/images/Employees/CustomerSupport/ShwethaSankapale.webp";
import SSharanKumar from "@/assets/images/Employees/CustomerSupport/SSharanKumar.webp";
import SantoshTamannaVaddar from "@/assets/images/Employees/CustomerSupport/SantoshTamannaVaddar.webp";
import SyedSalahuddinGhaznavi from "@/assets/images/Employees/CustomerSupport/SyedSalahuddin.webp";
import ShubhamSPhatak from "@/assets/images/Employees/CustomerSupport/ShubhamSPhatak.webp";
import MohsinaBegum from "@/assets/images/Employees/CustomerSupport/MohsinaBegum.webp";
import ErinushSangma from "@/assets/images/Employees/CustomerSupport/ErinushSangma.webp";
import ShahnazBanu from "@/assets/images/Employees/CustomerSupport/ShahnazBanu.webp";
import SheebaKhan from "@/assets/images/Employees/CustomerSupport/SheebaKhan.webp";
import SyedMuzammil from "@/assets/images/Employees/CustomerSupport/SyedMuzammil.webp";
import AfshanInhas from "@/assets/images/Employees/CustomerSupport/AfshanInhas.webp";
import RahulRoy from "@/assets/images/Employees/CustomerSupport/RahulRoy.webp";
import AmritaPritam from "@/assets/images/Employees/CustomerSupport/AmritaPritam.webp";
import ChiniKunaPatra from "@/assets/images/Employees/CustomerSupport/ChiniKunaPatra.webp";
import MohammedAyazBA from "@/assets/images/Employees/CustomerSupport/MohammedAyazBA.webp";
import SagarikaKhandualo from "@/assets/images/Employees/CustomerSupport/SagarikaKhandualo.webp";
import AsiyaBegum from "@/assets/images/Employees/CustomerSupport/AsiyaBegum.webp";
import MuhammedArif from "@/assets/images/Employees/CustomerSupport/MuhammedArif.webp";
import ShuvamBehera from "@/assets/images/Employees/CustomerSupport/ShuvamBehera.webp";
import Reenaz from "@/assets/images/Employees/CustomerSupport/Reenaz.webp";

const Team = () => {
  const team = [
    {
      name: "Kush Taneja",
      role: "Director",
      profile: KT,
      description:
        "Kush is a director at Trio Tech and is also the designated director for the purposes of FIU-IND. Kush shares a vision of bringing digital payments solutions to every Indian, enabling them with the ability to make payments easier and increase the inclusivity of all communities.",
    },
    {
      name: "Sambhav Anand Jain",
      role: "Director",
      profile: JS,
      description:
        "Sambhav forms the core management at Trio Tech. In his drive towards a digital India, Sambhav works closely with government and financial institutions like large banks, NPCI, UIDAI, etc. Sambhav is recognized as an industry leader and is leading the revolution of how the youth is getting access to hassle-free payment services in India.",
    },
    {
      name: "Richa Khandelwal",
      role: "Principal Nodal Officer and Company Secretary",
      profile: Richa,
      description:
        "Richa serves as the primary contact for RBI queries. As Company Secretary of Trio Tech, she ensures timely submission of reports and addresses user issues, liaising with the team for swift resolution. ",
    },
    {
      name: "Manan Vishal Manocha",
      role: "Secondary Nodal Officer and Head - Customer Support and Operations",
      profile: Manan,
      description:
        "Manan Vishal Manocha is the Secondary Nodal Officer and also leads the Customer Support Team. He ensures that all of the user issues are solved as soon as possible. ",
    },
    {
      name: "Prasanjeet Patil",
      role: "Head, Legal and Policy and Escalation Officer",
      profile: PSJ,
      description:
        "Prasanjeet Patil is the Legal/ Regulatory Compliance and Escalation Officer. He primarily focuses on general corporate, tech regulations and compliance, data privacy and policy.",
    },
    {
      name: "Chirag Maheshwari",
      role: "Chief Information Security Officer and Head - Engineering",
      profile: Chirag,
      description: "",
    },
    {
      name: "Anchit Shukla",
      role: "Head - KYC and Product",
      profile: Momo,
      description: "",
    },
    {
      name: "Saransh Agarwal",
      role: "Head - Risk, Fraud and Payments",
      profile: Saransh,
      description:
        "Saransh leads the Fraud and Risk & Payments charter. In this capacity, he oversees product development and manages fraud prevention strategies to ensure the integrity and security of our payment systems along with overall payment experience.",
    },
    {
      name: "Vishal Jain",
      role: "Head, Finance",
      profile: VishalJain,
      description: "",
    },
    {
      name: "Sandeep Srinivasan",
      role: "Head - Information Technology",
      profile: Sandeep,
      description:
        "Our in-house security wizard, Sandeep focuses on cyber security for both the internal team as well as the users. His expertise ensures that Trio remains secure from potential threats and malicious activities.",
    },
    {
      name: "Harjot Singh Oberai",
      role: "Engineering, Senior Manager",
      profile: Paaji,
      description: "",
    },
    {
      name: "Pratik Gajjar",
      role: "Engineering, Senior Manager",
      profile: PratikGajjar,
      description: "",
    },
    {
      name: "Raghav Sharma",
      role: "Engineering, Senior Manager",
      profile: RaghavSharma,
      description: "",
    },
    {
      name: "Ritwick Bhaduri",
      role: "KYC, Senior Manager",
      profile: Ritwick,
      description:
        "Ritwick leads the company's KYC product. His primary focus is to ensure strict adherence to KYC norms, while providing users with the best possible experience.",
    },
    {
      name: "Srinidhi D G",
      role: "Reconciliation",
      profile: SrinidhiDG,
      description:
        "Srinidhi focuses on the meticulous reconciliation of payments and ensures a seamless payment experience for users. He also manages chargebacks efficiently to maintain customer satisfaction.",
    },
    {
      name: "Amarnath Subburaj",
      role: "CX",
      profile: Amarnath,
      description:
        "Amarnath is dedicated to enhancing customer experience by providing comprehensive support across all channels, addressing payments, escalations, and app queries with efficiency. He also effectively oversees the customer service operations of the company.",
    },
    {
      name: "Sangam Chabbra",
      role: "Fraud Ops & Payments",
      profile: Sangam,
      description:
        "Sangam specialises in managing fraud and risk queries. She is dedicated to achieving zero frauds at Trio, and is continuously improving the payment experience for users through efficient payment operations.",
    },
    // {
    //   name: "Monica Satyakumar",
    //   role: "Payment Operations",
    //   profile: Monica,
    //   description:
    //     "Monica is part of the Payment Operations Team at Trio ensuring effective interventions at customer level and functional level by handling escalations, liaising with Partner Organisations and NPCI. She acts an effective channel among different teams to enable efficiency in day to day operations and warrant a seamless experience for the users.",
    // },
    {
      name: "Abhishek Das",
      role: "Tech",
      profile: AbhishekDas,
      description:
        "Abhishek Das is one of the top talents in the Tech Team. He takes care of the user experience and ensures that Trio Tech applications are bug-free.",
    },
    {
      name: "Sunil Kumar B",
      role: "Reconciliation",
      profile: Sunil,
      description:
        "Sunil Kumar B takes care of all things financial at Trio Tech. He plays a pivotal part in the Reconciliation Team to ensure a smooth payment experience for users.",
    },
    {
      name: "Arpita Singh",
      role: "Risk & Fraud Ops",
      profile: ArpitaSingh,
      description: "",
    },
    {
      name: "Mohammed Muazz Zuberi",
      role: "Product",
      profile: MuazzZuberi,
      description: "",
    },
    {
      name: "Saurabh Gothi",
      role: "Tech",
      profile: SaurabhGothi,
      description: "",
    },
    {
      name: "Shikhar Tyagi",
      role: "Tech",
      profile: ShikharTyagi,
      description: "",
    },
    {
      name: "Sandeep Singh",
      role: "Tech",
      profile: SandeepSingh,
      description: "",
    },
    {
      name: "Pranav Jain",
      role: "Tech",
      profile: PranavJain,
      description: "",
    },
    {
      name: "Banuteja B",
      role: "IT",
      profile: BanutejaB,
      description: "",
    },
    {
      name: "Aneesh Panda",
      role: "Engineering",
      profile: AneeshPanda,
      description: "",
    },
    {
      name: "Piyush Dagdiya",
      role: "Product",
      profile: PiyushDagdiya,
      description: "",
    },
    {
      name: "Raj Pandey",
      role: "Research And Analytics",
      profile: RajPandey,
      description: "",
    },
    {
      name: "Akschaya Balaji",
      role: "Research And Analytics",
      profile: AkschayaBalaji,
      description: "",
    },
    {
      name: "Lisha Kothari",
      role: "Product",
      profile: LishaKothari,
      description: "",
    },
    {
      name: "Shourya De",
      role: "Tech",
      profile: ShouryaDe,
      description: "",
    },
    {
      name: "Naman Narula",
      role: "Product",
      profile: NamanNarula,
      description: "",
    },
  ];
  const customerSupportTeam = [
    {
      name: "Chini Kuna Patra",
      role: "KYC",
      profile: ChiniKunaPatra,
      description: "",
    },
    {
      name: "Winston Richard",
      role: "KYC",
      profile: WinstonRichard,
      description: "",
    },
    {
      name: "Mohammed Ayaz B A",
      role: "KYC",
      profile: MohammedAyazBA,
      description: "",
    },
    {
      name: "Sagarika Khandualo",
      role: "KYC",
      profile: SagarikaKhandualo,
      description: "",
    },
    {
      name: "Rani Sahu",
      role: "KYC",
      profile: RaniSahu,
      description: "",
    },
    {
      name: "Syed Mahamooda",
      role: "KYC",
      profile: SyedMahamooda,
      description: "",
    },
    {
      name: "Sangita Mandal",
      role: "KYC",
      profile: SangitaMandal,
      description: "",
    },
    {
      name: "Pooja P Purohit",
      role: "KYC",
      profile: PoojaPPurohit,
      description: "",
    },
    {
      name: "S Sharan Kumar",
      role: "KYC",
      profile: SSharanKumar,
      description: "",
    },
    {
      name: "Amrita Pritam",
      role: "KYC",
      profile: AmritaPritam,
      description: "",
    },
    {
      name: "Yarab M",
      role: "KYC",
      profile: YarabM,
      description: "",
    },
    {
      name: "Asiya Begum",
      role: "KYC",
      profile: AsiyaBegum,
      description: "",
    },
    {
      name: "Muhammed Arif S",
      role: "KYC",
      profile: MuhammedArif,
      description: "",
    },
    {
      name: "Shuvam Behera",
      role: "KYC",
      profile: ShuvamBehera,
      description: "",
    },
    {
      name: "Reenaz Mangalore",
      role: "KYC",
      profile: Reenaz,
      description: "",
    },
    {
      name: "Sandarsh Bhaskar",
      role: "Escalations",
      profile: SandarshBhaskar,
      description: "",
    },
    {
      name: "Syed Salahuddin Ghaznavi",
      role: "Escalations",
      profile: SyedSalahuddinGhaznavi,
      description: "",
    },
    {
      name: "Shubham S Phatak",
      role: "Escalations",
      profile: ShubhamSPhatak,
      description: "",
    },
    {
      name: "Shahnaz Banu",
      role: "Escalations",
      profile: ShahnazBanu,
      description: "",
    },
    {
      name: "Syed Muzammil",
      role: "Escalations",
      profile: SyedMuzammil,
      description: "",
    },
    {
      name: "Shaik Tasneem",
      role: "Customer Support",
      profile: ShaikTasneem,
      description: "",
    },
    {
      name: "D Sai Krishna",
      role: "Customer Support",
      profile: DSaiKrishna,
      description: "",
    },
    {
      name: "Suresh Chandra Rana",
      role: "Customer Support",
      profile: SureshChandraRana,
      description: "",
    },
    {
      name: "Shaik Saleem",
      role: "Customer Support",
      profile: ShaikSaleem,
      description: "",
    },
    {
      name: "Sohan Bandekar",
      role: "Customer Support",
      profile: SohanBandekar,
      description: "",
    },
    {
      name: "Mohammed Abbas",
      role: "Customer Support",
      profile: MohammedAbbas,
      description: "",
    },
    {
      name: "Vishala Nidoni",
      role: "Customer Support",
      profile: VishalaNidoni,
      description: "",
    },
    {
      name: "Asfiya Khanum",
      role: "Customer Support",
      profile: AsfiyaKhanum,
      description: "",
    },
    {
      name: "Syed Saif",
      role: "Customer Support",
      profile: SyedSaif,
      description: "",
    },
    {
      name: "Sheikh Yousuf",
      role: "Customer Support",
      profile: SheikhYousuf,
      description: "",
    },
    {
      name: "Sweety Chetry",
      role: "Customer Support",
      profile: SweetyChetry,
      description: "",
    },
    {
      name: "Shaik Malik Basha",
      role: "Customer Support",
      profile: ShaikMalikBasha,
      description: "",
    },
    {
      name: "Syed Nadeem",
      role: "Customer Support",
      profile: SyedNadeem,
      description: "",
    },
    {
      name: "Preeti Rk",
      role: "Customer Support",
      profile: PreetiRK,
      description: "",
    },
    {
      name: "Arabinda Pator",
      role: "Customer Support",
      profile: ArabindaPator,
      description: "",
    },
    {
      name: "Winston Richard",
      role: "Customer Support",
      profile: WinstonRichard,
      description: "",
    },
    {
      name: "Rakesh Samal",
      role: "Customer Support",
      profile: RakeshSamal,
      description: "",
    },
    {
      name: "Thejas H S",
      role: "Customer Support",
      profile: ThejasHS,
      description: "",
    },
    {
      name: "Kajal Murmu",
      role: "Customer Support",
      profile: KajalMurmu,
      description: "",
    },
    {
      name: "Nital Purohit",
      role: "Customer Support",
      profile: NitalPurohit,
      description: "",
    },
    {
      name: "Rattan Gowda",
      role: "Customer Support",
      profile: RattanGowda,
      description: "",
    },
    {
      name: "Sandesh",
      role: "Customer Support",
      profile: SandeshUBilgundi,
      description: "",
    },
    {
      name: "Fathimathul Thehliya.K",
      role: "Customer Support",
      profile: FathimathulThehliyaK,
      description: "",
    },
    {
      name: "Madan Kumar S",
      role: "Customer Support",
      profile: MadanKumarS,
      description: "",
    },
    {
      name: "Inam Ali Khan",
      role: "Customer Support",
      profile: InamAliKhan,
      description: "",
    },
    {
      name: "Syed Ghouse Basha",
      role: "Customer Support",
      profile: SyedGhouseBasha,
      description: "",
    },
    {
      name: "Daneshwari Bandi",
      role: "Customer Support",
      profile: DaneshwariBandi,
      description: "",
    },
    {
      name: "Mizba Banu",
      role: "Customer Support",
      profile: MizbaBanu,
      description: "",
    },
    {
      name: "Baisnabi Sahoo",
      role: "Customer Support",
      profile: BaisnabiSahoo,
      description: "",
    },
    {
      name: "Bhagyshree Hebbali",
      role: "Customer Support",
      profile: BhagyshreeHebbali,
      description: "",
    },
    {
      name: "Sonali R",
      role: "Customer Support",
      profile: SonaliRamesh,
      description: "",
    },
    {
      name: "Shwetha",
      role: "Customer Support",
      profile: ShwethaSankapale,
      description: "",
    },
    {
      name: "Santosh Tamanna Vaddar",
      role: "Customer Support",
      profile: SantoshTamannaVaddar,
      description: "",
    },
    {
      name: "Mohsina Begum",
      role: "Customer Support",
      profile: MohsinaBegum,
      description: "",
    },
    {
      name: "Erinush Sangma",
      role: "Customer Support",
      profile: ErinushSangma,
      description: "",
    },
    {
      name: "Sheeba Khan",
      role: "Customer Support",
      profile: SheebaKhan,
      description: "",
    },
    {
      name: "Afshan Inhas",
      role: "Customer Support",
      profile: AfshanInhas,
      description: "",
    },
    {
      name: "Rahul Roy",
      role: "Customer Support",
      profile: RahulRoy,
      description: "",
    },
  ];

  const teamToShow = team.concat(customerSupportTeam);

  return (
    <div className="flex flex-wrap gap-4 px-4 justify-center w-full">
      {teamToShow.map((item) => (
        <div
          key={item.name}
          className="flex items-center justify-center mx-2 rounded-3xl h-[100%]"
        >
          <div className="h-[100%] w-full bg-gradient-to-b from-orange-500 to-green-500 p-[0.5px] rounded-3xl">
            <div className="flex h-full w-full items-center bg-white rounded-3xl p-8 flex-col min-h-[360px] min-w-[256px]">
              <Image
                className="rounded-full w-36 h-36 object-cover"
                src={item.profile}
                alt={item.name}
                width={100}
                height={100}
              />
              <h2 className="text-trio-black my-5 font-extrabold w-full max-w-[12rem] text-center">
                {item.name}
              </h2>
              <hr className="bg-black px-2 w-full opacity-100" />
              <p className="text-trio-black my-5 font-semibold w-full max-w-[12rem] text-center">
                {item.role}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Team;
